import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import companyReducer from './slices/company';
import userReducer from './slices/user';
import projectReducer from './slices/project';
import moduleReducer from './slices/module';
import releaseReducer from './slices/release';
import roleReducer from './slices/role';
import testRunReducer from './slices/testRun';
import auditLogReducer from './slices/auditLog';
import defectReducer from './slices/defect';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  auditLog: auditLogReducer,
  company: companyReducer,
  defect: defectReducer,
  module: moduleReducer,
  project: projectReducer,
  release: releaseReducer,
  role: roleReducer,
  testRun: testRunReducer,
  user: userReducer
});

export { rootPersistConfig, rootReducer };
