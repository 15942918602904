import axios from 'axios';
import API from '../services';
import { getSessionObj } from '../utils/jwt';
import { getIDBCurrentProject } from '../main';
import { HTTP_REQUEST } from '../Constants';
import { callBackendService } from '../redux/slices/common';
// utils

// ----------------------------------------------------------------------

export const getTestRunList = async () => {
  let response = null;
  try {
    const projectSerialized = await getIDBCurrentProject();
    if (projectSerialized && projectSerialized._id) {
      response = await axios({
        method: 'get',
        url: API.testRun.getJobsByPID(projectSerialized._id),
        headers: {
          Authorization: `Bearer ${getSessionObj('accessToken')}`
        }
      });
    }
  } catch (error) {
    console.log('error', error);
  }
  return response?.data;
};

// ----------------------------------------------------------------------

export const getTestRunById = async (testRunId) => {
  let response = null;
  try {
    response = await axios({
      method: 'get',
      url: `${API.testRun.getJobById(testRunId)}`,
      headers: {
        Authorization: `Bearer ${getSessionObj('accessToken')}`
      }
    });
  } catch (error) {
    console.log('error', error);
  }
  return response?.data;
};

// ----------------------------------------------------------------------

export const getTestRunStatus = async (jobId) => {
  let response = null;
  try {
    response = await callBackendService(HTTP_REQUEST.GET, `${API.releases.getTestRunStatus(jobId)}`, null);
  } catch (error) {
    console.log('error', error);
  }
  return response?.data;
};
