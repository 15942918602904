import * as Yup from 'yup';
import { useState, React } from 'react';
import { useSnackbar } from 'notistack5';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import axios from 'axios';
import { LoadingButton } from '@material-ui/lab';
import ReactDOM from 'react-dom';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { useDispatch, useSelector } from '../../../redux/store';
import { setUserIsSSO, setUserEmail, setUserRequestPassword } from '../../../redux/slices/user';
import url from '../../../URL';
import RenderComponent from '../../../RenderComponent';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { isSSO, requestPassword } = useSelector((state) => state.user);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().when([], {
      is: () => requestPassword,
      then: () => Yup.string().required('Password is required'),
      otherwise: () => Yup.string()
    })
  });

  const verifySSO = async (email) => {
    try {
      const response = await axios.post(`${url.host}/api/auth/verify?bypass=true`, {
        email
      });
      const { data } = response.data;

      if (data?.isSSO) {
        setUserIsSSO(dispatch, true);
        setUserRequestPassword(dispatch, false);
        const div = document.createElement('div');
        div.id = 'my-portal';
        document.body.appendChild(div);

        ReactDOM.render(<RenderComponent />, document.getElementById('my-portal'));
      }
      if (!data?.isSSO) {
        setUserIsSSO(dispatch, false);
        setUserRequestPassword(dispatch, true);
      }
    } catch (err) {
      enqueueSnackbar('Invalid User', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        if (!requestPassword) {
          await verifySSO(values.email);
          if (isSSO)
            enqueueSnackbar('LoggingIn ...', {
              variant: 'success',
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
        } else if (requestPassword) {
          await login(values.email, values.password);
          enqueueSnackbar('Login success', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error('err', error.message);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.response.data.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, setFieldValue, getFieldProps } = formik;

  const handleEmailChange = (event) => {
    const { value } = event.target;
    if (!requestPassword) {
      setUserIsSSO(dispatch, false);
      setFieldValue('email', value);
      setUserEmail(dispatch, value);
      setUserRequestPassword(dispatch, false);
    }
  };

  const handleEmailClick = (event) => {
    const { value } = event.target;
    setUserIsSSO(dispatch, false);
    setFieldValue('email', value);
    setUserEmail(dispatch, value);
    setUserRequestPassword(dispatch, false);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            // onClick={handleEmailClick}
            onClick={handleEmailClick}
            onChange={handleEmailChange}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          {requestPassword && (
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {isSSO ? 'Login' : 'Next'}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
