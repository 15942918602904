export const STATUS = ['ARCHIVED', 'CLOSED'];

export const USER_ROLES = {
  ADMIN: 'R0001',
  CLIENT_ADMIN: 'R0002',
  QA_ENGINEER: 'R0003',
  RELEASE_MANAGER: 'R0004',
  EXECUTOR: 'R0005',
  EXECUTIVES: 'R0006',
  AUTOMATION_USER: 'R0007'
};

export const JOB_STATUS = { UNTESTED: 'UNTESTED', PASSED: 'PASSED', SKIPPED: 'SKIPPED', FAILED: 'FAILED' };

export const PERSIST_STORE = { USER: 'redux-user', PROJECT: 'redux-project' };

export const highlight = ['[PASSED]', '[FAILED]', '[SKIPPED]'];

// export default { STATUS, PERSIST_STORE };

export const HTTP_REQUEST = { GET: 'get', POST: 'post', PATCH: 'patch', DELETE: 'delete' };

const Constants = { STATUS, PERSIST_STORE };

export const ACCESS_TOKEN = 'accessToken';

export const LOGGED_IN_TIME = 'loggedintime';

export const CURRENT_USER = 'currentUser';

export const CURRENT_PROJECT = 'currentProject';

export const INDEXEDDB_NAME = 'tedata';

export const IndexedDB = {
  USER: 'user',
  ROLE: 'role',
  COMPANY: 'company',
  PROJECT: 'project',
  MODULE: 'module',
  RELEASE: 'release',
  TESTRUN: 'testRun'
};

export const INDEXEDDB_PERMISSIONS = { READ_WRITE: 'readwrite', READ_ONLY: 'readonly' };

export const INDEXEDDB_KEYS = {
  CURRENT_USER,
  CURRENT_PROJECT,
  CURRENT_MODULE: 'currentModule',
  CURRENT_RELEASE: 'currentRelease',
  CURRENT_TESTRUN: 'currentTestRun',
  ROLE_CONFIG: 'roleConfig',
  SELECTED_TESTCASE: 'selectedTestCase',
  TESTSTEP_RESULTS: 'testStepResults',
  EVIDENCES_FETCHED: 'evidencesFetched',
  TESTSTEP_DETAILS: 'testStepDetails',
  TESTSTEP_LOGS: 'testStepLogs',
  CURRENT_FUNCTION: 'currentFunction',
  TESTCASE_EVIDENCES_FECTHED: 'testCaseEvidencesFetched'
};

export const RELEASE_SCHEDULE = {
  NO_REPEAT: 'No Repeat',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly'
};

export const GEN_AI_INPUT = {
  TEXT: 'text',
  IMAGE: 'image'
};

export default Constants;
