/* eslint-disable prettier/prettier */
const APP_HOST_URL = process.env.REACT_APP_API_BASE_URL;

const API_VERSION = '/api'; // /v1

const version = 'v1';

// endpoints
const API = {
  auth: {
    signIn: `${APP_HOST_URL}${API_VERSION}/auth/signin`,
    forgotPassword: `${APP_HOST_URL}${API_VERSION}/auth/forgotpassword`,
    resetPassword: `${APP_HOST_URL}${API_VERSION}/auth/${version}/resetpassword`
  },
  sso: {
    verify: `${APP_HOST_URL}${API_VERSION}/auth/verify`,
    validation: `${APP_HOST_URL}${API_VERSION}/auth/validation`
  },
  companies: {
    getAllCompanies: `${APP_HOST_URL}${API_VERSION}/users/${version}/getAllCompanies`,
    createCompany: `${APP_HOST_URL}${API_VERSION}/users/${version}/createCompany`,
    updateCompany: `${APP_HOST_URL}${API_VERSION}/users/${version}/updateCompany`,
    deleteCompany: `${APP_HOST_URL}${API_VERSION}/users/${version}/deleteCompany`
  },
  roles: {
    getAllRoles: `${APP_HOST_URL}${API_VERSION}/rolemanage/${version}/getRoles`,
    createRole: `${APP_HOST_URL}${API_VERSION}/rolemanage/${version}/createRole`,
    updateRole: `${APP_HOST_URL}${API_VERSION}/rolemanage/${version}/updateRole`,
    deleteRole: `${APP_HOST_URL}${API_VERSION}/rolemanage/${version}/deleteRole`,
    createRoleConfig: `${APP_HOST_URL}${API_VERSION}/rolemanage/${version}/RoleManage`,
    getRoleConfig: `${APP_HOST_URL}${API_VERSION}/rolemanage/${version}/getRoleManage`,
    getClientAdminRoleConfig: `${APP_HOST_URL}${API_VERSION}/rolemanage/${version}/getRoleManage/client`,
    getClientRoleConfigList: `${APP_HOST_URL}${API_VERSION}/rolemanage/${version}/getRoleManage/list`
  },
  users: {
    getAllUsers: (companyId) => `${APP_HOST_URL}${API_VERSION}/users/allUsers/${companyId}`,
    getAllTemplates: `${APP_HOST_URL}${API_VERSION}/users/allTemplates`,
    getAllRoles: `${APP_HOST_URL}${API_VERSION}/users/allRoles`,
    getUser: `${APP_HOST_URL}${API_VERSION}/users/User`,
    createUser: `${APP_HOST_URL}${API_VERSION}/users/${version}/createUser`,
    updateUser: (id) => `${APP_HOST_URL}${API_VERSION}/users/User/update/${id}`,
    deleteUser: (id) => `${APP_HOST_URL}${API_VERSION}/users/User/delete/${id}`
  },
  projects: {
    allProjects: (companyId) => `${APP_HOST_URL}${API_VERSION}/projects/allProjects/${companyId}`,
    allArchivedProjects: (companyId, userId) =>
      `${APP_HOST_URL}${API_VERSION}/projects/${version}/findProjects/{"status":["ARCHIVED"]}/${companyId}/${userId}`,
    allUnArchivedProjects: (companyId, userId) =>
      `${APP_HOST_URL}${API_VERSION}/projects/${version}/findProjects/{"status":["ACTIVE","CLOSED"]}/${companyId}/${userId}`,
    getProject: (projectId) => `${APP_HOST_URL}${API_VERSION}/projects/Project/${projectId}`,
    findProject: (projectId) => `${APP_HOST_URL}${API_VERSION}/projects/${version}/findProject/${projectId}`,
    createProject: `${APP_HOST_URL}${API_VERSION}/projects/createProject`,
    updateProject: `${APP_HOST_URL}${API_VERSION}/projects/Project/update`,
    deleteProject: `${APP_HOST_URL}${API_VERSION}/projects/Project/delete`,
    allModules: `${APP_HOST_URL}${API_VERSION}/projects/allModules`,
    getModuleByProjectId: (id) => `${APP_HOST_URL}${API_VERSION}/projects/ModuleByPID/${id}`,
    getModulesByProjectId: (id) => `${APP_HOST_URL}${API_VERSION}/projects/ModulesByPID/${id}`,
    getModulesList: (moduleId) => `${APP_HOST_URL}${API_VERSION}/projects/${version}/ModuleByPID/${moduleId}`,
    getModuleDetails: (id) => `${APP_HOST_URL}${API_VERSION}/projects/${version}/ModuleDetails/${id}/All`,
    projectModules: `${APP_HOST_URL}${API_VERSION}/projects/Project`,
    createModule: `${APP_HOST_URL}${API_VERSION}/projects/createModule`,
    parseTestCases: `${APP_HOST_URL}${API_VERSION}/projects/parseTestCases`,
    createManualModule: `${APP_HOST_URL}${API_VERSION}/projects/createManualModule`,
    updateModule: (id) => `${APP_HOST_URL}${API_VERSION}/projects/Module/update/${id}`,
    deleteModule: `${APP_HOST_URL}${API_VERSION}/projects/Module/delete`,
    downLoadModule: `${APP_HOST_URL}${API_VERSION}/projects/downloadModule`,
    getTemplates: `${APP_HOST_URL}${API_VERSION}/projects/getTemplates`,
    getTemplatesByCompanyId: (companyId) => `${APP_HOST_URL}${API_VERSION}/projects/getTemplates/${companyId}`,
    getTestCaseSteps: (companyId, projectId) =>
      `${APP_HOST_URL}${API_VERSION}/projects/getTestCaseSteps/${companyId}/${projectId}`
  },
  releases: {
    release: `${APP_HOST_URL}${API_VERSION}/releases/Release/XMLConfig`,
    getRelease: (id) => `${APP_HOST_URL}${API_VERSION}/releases/Release/${id}`,
    deleteRelease: `${APP_HOST_URL}${API_VERSION}/releases/Release/delete`,
    getAllReleases: `${APP_HOST_URL}${API_VERSION}/releases/allReleases`,
    getReleaseByPID: (projectID) => `${APP_HOST_URL}${API_VERSION}/releases/${version}/ReleaseByPID/${projectID}`,
    getReleaseStatus: (releaseId) => `${APP_HOST_URL}${API_VERSION}/releases/v2/getReleaseStatus/${releaseId}`,
    getTestRunStatus: (jobId) => `${APP_HOST_URL}${API_VERSION}/releases/v2/getTestRunStatus/${jobId}`,
    createRelease: `${APP_HOST_URL}${API_VERSION}/releases/createRelease`,
    getRelease2Edit: (releaseId) => `${APP_HOST_URL}${API_VERSION}/releases/${version}/getRelease/${releaseId}`,
    updateRelease: `${APP_HOST_URL}${API_VERSION}/releases/${version}/updateRelease`,
    createJenkinsJob: `${APP_HOST_URL}${API_VERSION}/releases/createJenkinsJob`,
    getAllJobs: `${APP_HOST_URL}${API_VERSION}/releases/AllJobs`,
    testResults: `${APP_HOST_URL}${API_VERSION}/releases/TestResults/fetch`,
    getReleaseExecutionDuration: `${APP_HOST_URL}${API_VERSION}/releases/${version}/getReleaseExecutionDuration`,
    exportModule: `${APP_HOST_URL}${API_VERSION}/releases/${version}/exportModule`
  },
  testsuites: {
    getExecutionData: `${APP_HOST_URL}${API_VERSION}/testsuites/TestSuite`,
    getTestSuitesByModules: `${APP_HOST_URL}${API_VERSION}/testsuites/getTestSuitesByModules`
  },
  testRun: {
    getReleaseTestRunVersion: `${APP_HOST_URL}${API_VERSION}/releases/${version}/getReleaseTestRunVersion`,
    createJob: (releaseId) => `${APP_HOST_URL}${API_VERSION}/releases/${version}/createJob/${releaseId}`,
    reRunJob: (jobId) => `${APP_HOST_URL}${API_VERSION}/releases/reRunJob/${jobId}`,
    stopJob: (jobId) => `${APP_HOST_URL}${API_VERSION}/releases/stopJob/${jobId}`,
    getJobsByPID: (projectId) => `${APP_HOST_URL}${API_VERSION}/projects/${version}/allJobs/${projectId}`,
    getJobById: (jobId) => `${APP_HOST_URL}${API_VERSION}/releases/jobDetails/${jobId}`,
    getJobUpdate: (jobId, id, status) => `${APP_HOST_URL}${API_VERSION}/releases/jobUpdate/${jobId}/${id}/${status}`,
    releaseTestRuns: (releaseId) => `${APP_HOST_URL}${API_VERSION}/releases/allJobs/${releaseId}`,
    releaseInfo: (releaseId) => `${APP_HOST_URL}${API_VERSION}/releases/${version}/ReleaseInfo/${releaseId}`,
    testCaseUpdate: (testRunId, moduleID, id, testCaseId) =>
      `${APP_HOST_URL}${API_VERSION}/releases/testCaseUpdate/${testRunId}/${moduleID}/${id}/${testCaseId}`,
    getTestCaseResults: (fileName) => `${APP_HOST_URL}${API_VERSION}/releases/getTestCaseResults/${fileName}`,
    getTestCaseLogs: (fileName) => `${APP_HOST_URL}${API_VERSION}/releases/getTestCaseLogs/${fileName}`,
    getTestResultFiles: `${APP_HOST_URL}${API_VERSION}/releases/${version}/gettestresultfiles`,
    getGenAITestCases: `${APP_HOST_URL}${API_VERSION}/releases/${version}/getGenAITestCases`
  },
  defectTracker: {
    createUser: `${APP_HOST_URL}${API_VERSION}/users/${version}/createUserinDefectTracker`,
    getIssues: `${APP_HOST_URL}${API_VERSION}/users/${version}/Issues/list`
  },
  auditLog: {
    getAduitLogs: (company) => `${APP_HOST_URL}${API_VERSION}/audits/Audit/${company}`
  },
  validate: {
    getValidations: () => `${APP_HOST_URL}${API_VERSION}/projects/getValidations`
  }
};

export default API;
