// ----------------------------------------------------------------------

export const fullName = [
  'Jayvion Simon',
  'Lucian Obrien',
  'Deja Brady',
  'Harrison Stein',
  'Reece Chung',
  'Lainey Davidson',
  'Cristopher Cardenas',
  'Melanie Noble',
  'Chase Day',
  'Shawn Manning',
  'Soren Durham',
  'Cortez Herring',
  'Brycen Jimenez',
  'Giana Brandt',
  'Aspen Schmitt',
  'Colten Aguilar',
  'Angelique Morse',
  'Selina Boyer',
  'Lawson Bass',
  'Ariana Lang',
  'Amiah Pruitt',
  'Harold Mcgrath',
  'Esperanza Mcintyre',
  'Mireya Conner',
  'Jamie Kline',
  'Laney Vazquez',
  'Tiffany May',
  'Dexter Shepherd',
  'Jaqueline Spencer',
  'Londyn Jarvis',
  'Yesenia Butler',
  'Jayvon Hull',
  'Izayah Pope',
  'Ayana Hunter',
  'Isabell Bender',
  'Desiree Schmidt',
  'Aidan Stout',
  'Jace Bush',
  'Janiya Williamson',
  'Hudson Alvarez'
];

export const firstName = [
  'Mossie',
  'David',
  'Ebba',
  'Chester',
  'Eula',
  'Jaren',
  'Boyd',
  'Brady',
  'Aida',
  'Anastasia',
  'Gregoria',
  'Julianne',
  'Ila',
  'Elyssa',
  'Lucio',
  'Lewis',
  'Jacinthe',
  'Molly',
  'Brown',
  'Fritz',
  'Keon',
  'Ella',
  'Ken',
  'Whitney',
  'Monte',
  'Rose',
  'Shana',
  'Devon',
  'Jaleel',
  'Laury',
  'Brooks',
  'Bruce',
  'Avery',
  'Esperanza',
  'Helene',
  'Heloise',
  'Elinor',
  'Adeline',
  'Haley',
  'Anabelle'
];

export const lastName = [
  'Carroll',
  'Simonis',
  'Yost',
  'Hand',
  'Emmerich',
  'Wilderman',
  'Howell',
  'Sporer',
  'Boehm',
  'Morar',
  'Koch',
  'Reynolds',
  'Padberg',
  'Watsica',
  'Upton',
  'Yundt',
  'Pfeffer',
  'Parker',
  'Zulauf',
  'Treutel',
  'McDermott',
  'McDermott',
  'Cruickshank',
  'Parisian',
  'Auer',
  'Turner',
  'Dooley',
  'Wiegand',
  'Abbott',
  'Wisoky',
  'Fahey',
  'Satterfield',
  'Bahringer',
  'Schulist',
  'Durgan',
  'Carroll',
  'Jones',
  'Leffler',
  'Gutkowski',
  'Homenick'
];

export const projectName = [
  'Infor_LN_V2',
  'Infor_LN_V2_JanRelease',
  'Infor_LN_V2_MarRelease',
  'Infor_LN_MajorUpgrade',
  'Infor_LN_V2_Jun2022',
  'Infor_LN_Release'
];

export const releaseName = [
  'Infor_LN_V2-Rel',
  'Infor_LN_V2_JanRelease',
  'Infor_LN_V2_MarRelease',
  'Infor_LN_MajorUpgrade',
  'Infor_LN_V2_Jun2022',
  'Infor_LN_Release',
  'Infor_Test'
];

export const moduleName = [
  'ComminglingPurchaseOrders',
  'ConsignmentInventory',
  'DirectDeliverySalesOrder',
  'DomesticPurchaseOrder',
  'ImportPurchaseOrder',
  'MaintenanceSalesOrder',
  'OrderToCash',
  'POSubcontracting',
  'ProcessDeleiveredPurchaseOrder',
  'ProcureToPay',
  'ProductionOrder',
  'PurchaseBackOrder',
  'PurchaseCostOrder',
  'PurchaseOrder',
  'PurchaseOrderInspection',
  'PurchaseRequestforQuotation',
  'PurchaseRequisition',
  'PurchaseReturnOrder',
  'QuarantineInventory',
  'SalesCostOrder',
  'SalesDeliveredPurchaseOrderAdvice',
  'SalesOrder',
  'SalesOrderWithLot',
  'SalesReturnOrder'
];
export const testCaseName = [
  'Verify Login with Valid Credentials',
  'Verify Order Intake Workbench',
  'Verify New SO form',
  'Verify Inactive BP for SO',
  'Verify Active BP for SO',
  'Verify Invalid Order Type for SO',
  'Verify Valid Order Type for SO',
  'Verify Invalid Sales office',
  'Verify Valid Sales office',
  'Verify Invalid Order series',
  'Verify Valid Order Series',
  'Verify Inactive Ship From BP',
  'Verify Ship From BP Not Linked to BP',
  'Verify Active Ship From BP',
  'Verify Invalid Currency',
  'Verify Non Local Currency',
  'Verify Valid Currency',
  'Verify PO Generation',
  'Verify New Order Lines',
  'Verify Invalid Item',
  'Verify Valid Item',
  'Verify Zero Qty',
  'Verify Valid Qty',
  'Verify Price Entry',
  'Verify Save Order',
  'Verify Approve Order',
  'Verify Print PO',
  'Navigation to Warehouse',
  'Search Warehouse Order',
  'Verify Warehousing order Session',
  'Verify Outbound advice',
  'Verify Freeze/Confirm',
  'Verify Confirm',
  'Verify Outbound line status overview Line status',
  'Verify Outbound line status overview Line status',
  'Verify Invoicing 360',
  'Verify Process Delivered Sales Orders',
  'Verify Sales Order status'
];

export const testCaseStepName = [
  'Naviagte to the Infor Cloud Suite Homepage',
  'Login with username and password',
  'Validate OK button is displayed',
  'Validate Infor LN Menu Item is displayed',
  'Validate Infor LN Menu Item is displayed',
  'Click on Infor LN Menu Iten',
  'Click on Sales under Infor LN Menu Item',
  'Click on Orders under Sales',
  'Validate Order Intake Workbench option is displayed',
  'Click on Order Intake Workbench under Order',
  'Validate Sales Order Intake WorkBench Tab is displayed',
  'Click on new Button to create new Sales Order',
  'Validate Sales Order Intake WorkBench Tab is displayed',
  'Enter Invalid Business Partner',
  'Validate Error message is displayed',
  'Click OK to close the error message',
  'Click on search icon on the business partner field',
  'Enter the active BP on the seach filter',
  'Clck on the row check box',
  'Click OK button',
  'Enter Invalid Order Type',
  'Validate Error message is displayed',
  'Click OK to close the error message',
  'Click on search icon on the order type field',
  'Enter the valid order type on the seach filter',
  'Clck on the row check box',
  'Click OK button',
  'Enter Invalid Sales Office',
  'Validate Error message is displayed',
  'Click OK to close the error message',
  'Click on search icon on the sales office field',
  'Enter the valid sales office on the seach filter',
  'Clck on the row check box',
  'Click OK button',
  'Enter Invalid Order Series',
  'Validate Error message is displayed'
];
