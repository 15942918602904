// utils
import axios from 'axios';
// import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getSessionObj, setSessionObj } from '../../utils/jwt';
import { HTTP_REQUEST, PERSIST_STORE } from '../../Constants';
import {
  setDefaultRoleConfig,
  setClientsRoleConfig,
  setRoleConfig,
  setRolesList,
  setPageConfig,
  setFetchRoledata
} from './role';
import { setProjectList } from './project';
import { setModuleList } from './module';
import { setReleaseList, setExecutionDuration, setModuleData } from './release';
import { setTestRunsList, setAllTestRunsList } from './testRun';
import { setUserList, setUserIsSSO, setUserRequestPassword, setUserAppendUrl, setCurrentUser } from './user';
import { setCompanyList } from './company';
import { getReleaseStatus } from '../../_apis_/release';
import { getTestRunStatus } from '../../_apis_/testRun';
import { setIDBCurrentUser } from '../../main';

// ----------------------------------------------------------------------

export const callBackendService = async (method, url, data) => {
  let response = null;
  if ([HTTP_REQUEST.GET, HTTP_REQUEST.DELETE].includes(method)) {
    response = await axios({
      method,
      url,
      headers: {
        Authorization: `Bearer ${getSessionObj('accessToken')}`
      }
    });
  } else {
    response = await axios({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${getSessionObj('accessToken')}`
      }
    });
  }

  return response?.data;
};

export const clearSSOData = (dispatch) => {
  setUserIsSSO(dispatch, true);
  setFetchRoledata(dispatch, false);
  setRoleConfig(dispatch, null);
  setRolesList(dispatch, null);
  setPageConfig(dispatch, null);
  setDefaultRoleConfig(dispatch, null);
  setClientsRoleConfig(dispatch, null);
  setProjectList(dispatch, null);
  setModuleList(dispatch, null);
  setReleaseList(dispatch, null);
  setTestRunsList(dispatch, null);
  setAllTestRunsList(dispatch, null);
  setUserList(dispatch, []);
  setCompanyList(dispatch, null);
  setUserAppendUrl(dispatch, null);
  setCurrentUser(dispatch, null);
  setSessionObj(PERSIST_STORE.USER);
  setIDBCurrentUser(null);
};

export const clearSSO = (dispatch) => {
  setUserAppendUrl(dispatch, null);
};

export const clearData = (dispatch) => {
  setIDBCurrentUser(null);
  setSessionObj(PERSIST_STORE.USER);
  setCurrentUser(dispatch, null);
  setUserIsSSO(dispatch, false);
  setFetchRoledata(dispatch, true);
  setRoleConfig(dispatch, null);
  setRolesList(dispatch, null);
  setPageConfig(dispatch, null);
  setDefaultRoleConfig(dispatch, null);
  setClientsRoleConfig(dispatch, null);
  setProjectList(dispatch, null);
  setModuleList(dispatch, null);
  setReleaseList(dispatch, null);
  setTestRunsList(dispatch, null);
  setAllTestRunsList(dispatch, null);
  setUserList(dispatch, []);
  setCompanyList(dispatch, null);
  setUserIsSSO(dispatch, true);
  setUserRequestPassword(dispatch, false);
  setUserAppendUrl(dispatch, null);
};

export const allModulesClearData = (dispatch) => {
  setRolesList(dispatch, null);
  setFetchRoledata(dispatch, true);
  setReleaseList(dispatch, null);
  setTestRunsList(dispatch, null);
  setAllTestRunsList(dispatch, null);
};

export const allReleasesClearData = (dispatch) => {
  setRolesList(dispatch, null);
  setModuleList(dispatch, null);
  setTestRunsList(dispatch, null);
  setAllTestRunsList(dispatch, null);
};

export const testRunsClearData = (dispatch) => {
  setRolesList(dispatch, null);
  setModuleList(dispatch, null);
  setReleaseList(dispatch, null);
};

export const refreshTestCases = async (dispatch, releaseId, testRunId) => {
  setExecutionDuration(dispatch, null);
  let moduleData;
  if (releaseId) moduleData = await getReleaseStatus(releaseId);
  else if (testRunId) moduleData = await getTestRunStatus(testRunId);
  setModuleData(dispatch, moduleData?.modules);
};

// export const downloadExcel = (data, fileName) => {
//   try {
//     const workbook = XLSX.utils.book_new();
//     data?.forEach((excelData) => {
//       const worksheet = XLSX.utils.json_to_sheet(excelData?.edata);
//       XLSX.utils.book_append_sheet(workbook, worksheet, excelData?.sname);
//     });
//     XLSX.writeFile(workbook, fileName);
//   } catch (error) {
//     console.log('error', error);
//   }
// };

// const jsonData = [
//   { name: 'John Doe', age: 28, city: 'New York' },
//   { name: 'Jane Smith', age: 32, city: 'Los Angeles' },
//   { name: 'Michael Johnson', age: 45, city: 'Chicago' }
// ];

export const exportToExcel = async (data, testCaseID, fileName, manual) => {
  try {
    console.log(data);
    const workbook = new ExcelJS.Workbook();
    data?.forEach((sData) => {
      if (manual) {
        const mworksheet = workbook.addWorksheet('Index');
        const data = [
          { suiteName: 'Gen AI Test Cases', suiteDescription: 'Gen AI Test Cases', sheetName: sData.sname }
        ];
        mworksheet.columns = [
          { header: 'Suite Name', key: 'suiteName', width: 30 },
          { header: 'Suite Description', key: 'suiteDescription', width: 30 },
          { header: 'Associated Sheet Name', key: 'sheetName', width: 30 }
        ];

        mworksheet.addRows(data);

        // Apply styles
        mworksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          row.eachCell({ includeEmpty: false }, (cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            cell.border = {
              top: { style: 'hair' },
              left: { style: 'hair' },
              bottom: { style: 'hair' },
              right: { style: 'hair' }
            };
            if (rowNumber === 1) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '46d246' }
              };
              cell.font = {
                name: 'Calibri',
                bold: true,
                color: { argb: '000000' }
              };
            }
          });
        });
      }

      const worksheet = workbook.addWorksheet(sData.sname);

      // Add header row
      if (manual) {
        worksheet.columns = [
          { header: 'ID', key: 'testCaseID', width: 15 },
          { header: 'Depends On', key: 'dependsOn', width: 15 },
          { header: 'Test Title', key: 'testCaseTitle', width: 30 },
          { header: 'Test Case Description', key: 'testCaseDescription', width: 30 },
          { header: 'Step No', key: 'StepNo', width: 10 },
          { header: 'Step Description', key: 'testStepDescription', width: 40 },
          { header: 'Expected', key: 'Expected', width: 40 }
        ];
      } else if (testCaseID) {
        worksheet.columns = [
          { header: 'Test Case ID', key: 'testCaseID', width: 15 },
          { header: 'Test Case Title', key: 'testCaseTitle', width: 30 },
          { header: 'Test Case Description', key: 'testCaseDescription', width: 30 },
          { header: 'Step No', key: 'StepNo', width: 10 },
          { header: 'Test Step Description', key: 'testStepDescription', width: 40 },
          { header: 'Expected', key: 'Expected', width: 40 }
        ];
      } else {
        worksheet.columns = [
          { header: 'Test Case Title', key: 'testCaseTitle', width: 30 },
          { header: 'Test Case Description', key: 'testCaseDescription', width: 30 },
          { header: 'Step No', key: 'StepNo', width: 10 },
          { header: 'Test Step Description', key: 'testStepDescription', width: 40 },
          { header: 'Expected', key: 'Expected', width: 40 }
        ];
      }

      // Add rows
      worksheet.addRows(sData.edata);

      // Apply styles
      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: false }, (cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          cell.border = {
            top: { style: 'hair' },
            left: { style: 'hair' },
            bottom: { style: 'hair' },
            right: { style: 'hair' }
          };
          if (rowNumber === 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '46d246' }
            };
            cell.font = {
              name: 'Calibri',
              bold: true,
              color: { argb: '000000' }
            };
          }
        });
      });
    });

    // Create a buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  } catch (error) {
    console.log('error', error);
  }
};

export const exportToMergeExcel = async (data, testCaseID, mergeCells, fileName) => {
  try {
    console.log(data);
    const workbook = new ExcelJS.Workbook();
    data?.forEach((sData) => {
      const worksheet = workbook.addWorksheet(sData.sname);

      // Add header row
      if (testCaseID) {
        worksheet.columns = [
          { header: 'Test Case ID', key: 'testCaseID', width: 15 },
          { header: 'Test Case Title', key: 'testCaseTitle', width: 30 },
          { header: 'Test Case Description', key: 'testCaseDescription', width: 30 },
          { header: 'Step No', key: 'StepNo', width: 10 },
          { header: 'Test Step Description', key: 'testStepDescription', width: 40 },
          { header: 'Expected', key: 'Expected', width: 40 }
        ];
      } else {
        worksheet.columns = [
          { header: 'Test Case Title', key: 'testCaseTitle', width: 30 },
          { header: 'Test Case Description', key: 'testCaseDescription', width: 30 },
          { header: 'Step No', key: 'StepNo', width: 10 },
          { header: 'Test Step Description', key: 'testStepDescription', width: 40 },
          { header: 'Expected', key: 'Expected', width: 40 }
        ];
      }

      // Add rows
      worksheet.addRows(sData.edata);

      // Apply styles
      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: false }, (cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          cell.border = {
            top: { style: 'hair' },
            left: { style: 'hair' },
            bottom: { style: 'hair' },
            right: { style: 'hair' }
          };
          if (rowNumber === 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '46d246' }
            };
            cell.font = {
              name: 'Calibri',
              bold: true,
              color: { argb: '000000' }
            };
          }
        });
      });

      let startCell = 2;
      let endCell = parseInt(mergeCells[0] + 1, 10);

      mergeCells?.forEach((mergeCell, index) => {
        try {
          if (index !== 0) startCell = parseInt(endCell + 1, 10);
          endCell = parseInt(startCell + mergeCell - 1, 10);
          worksheet.mergeCells(`E${startCell}`, `E${endCell}`);
        } catch (err) {
          console.log('err', err);
        }
      });
    });

    // Create a buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  } catch (error) {
    console.log('error', error);
  }
};

export default { clearData, callBackendService };
